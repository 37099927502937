











































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { ArrowLeftIcon, ThumbsUpIcon, PlusIcon } from "vue-feather-icons";
import Loader from "../components/ui/Loader.vue";
import PhotoViewer from "../components/assets-detail/Photo-Viewer.vue";
import PdfViewer from "../components/assets-detail/Pdf-Viewer.vue";
import DateTimePicker from "../components/ui/DateTime-Picker.vue";

@Component({
  components: {
    ArrowLeftIcon,
    ThumbsUpIcon,
    PlusIcon,
    Loader,
    PhotoViewer,
    PdfViewer,
    DateTimePicker,
  },
})
export default class NewMaintenance extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  isEditModeActive = false;

  $refs!: {
    machine_model: HTMLInputElement;
    fileInput: HTMLInputElement;
    fileInput1: HTMLInputElement;
  };
  mounted() {
    this.fetchAssetsList();

    const id = this.$router.currentRoute.params.id || null;
    if (id !== null) {
      this.isEditModeActive = true;
      this.isDataLoading = true;
      this.fetchAssetWithId(id);
    } else {
      setTimeout(() => {
        this.$refs.machine_model.focus();
      }, 10);
    }
  }

  async fetchAssetWithId(id: string) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.id = data.id;
        this.asset_id = data.asset_id;
        this.title = data.title;
        this.description = data.description;
        this.scheduled_date = data.scheduled_date;
        this.status = data.status;
        this.type = data.type;
        // this.spared_replaced = data.spared_replaced;
        // this.costing = data.cost_whole;
        // this.broken_spared_returned = data.broken_spared_returned;
        // this.consumable_spare_cost = data.consumable_spare_cost;
        // this.replaced_spare_cost = data.replaced_spare_cost;

        setTimeout(() => {
          this.$refs.machine_model.focus();
        }, 10);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isDataLoading = false;
    }
  }

  // variables
  id = "";

  assetsList: any = [];
  asset_id = "";
  asset_idError = "";

  title = "";
  titleError = "";

  description = "";
  descriptionError = "";

  scheduled_date = "";
  scheduled_dateError = "";

  completion_date = "";
  completion_dateError = "";

  status = "scheduled";
  statusError = "";

  type = "regular";
  typeError = "";

  cost_whole = "";
  cost_wholeError = "";

  cost_decimal = "0";
  cost_decimalError = "";

  @Watch("title") onwatchtitle() {
    this.titleError = "";
  }
  @Watch("description") onwatchdescription() {
    this.descriptionError = "";
  }
  onScheduleDateChanged(e: any) {
   this.scheduled_date = e;
   this.scheduled_dateError = "";
  }
  // onCompletionDateChanged(e: any) {
  //   this.completion_date = e;
  //   this.completion_dateError = "";
  // }
  @Watch("status") onwatchstatus() {
    this.statusError = "";
  }
  @Watch("type") onwatchtype() {
    this.typeError = "";
  }
  // @Watch("cost_whole") onwatchcost_whole() {
  //   this.cost_wholeError = "";
  // }
  // @Watch("cost_decimal") onwatchcost_decimal() {
  //   this.cost_decimalError = "";
  // }
  // @Watch("consumable_spare_cost") onwatchconsumable_spare_cost() {
  //   this.consumable_spare_costError = "";
  //   this.costing =
  //     Number(this.consumable_spare_cost) + Number(this.replaced_spare_cost);
  // }
  // @Watch("replaced_spare_cost") onwatchreplaced_spare_cost() {
  //   this.replaced_spare_costError = "";
  //   this.costing =
  //     Number(this.consumable_spare_cost) + Number(this.replaced_spare_cost);
  // }

  isDataLoading = true;
  async fetchAssetsList() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/list`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        this.assetsList = await response.json();
        const id: any = this.$router.currentRoute.query.id || null;
        if (id != null) {
          this.asset_id = id;
        } else {
          this.asset_id = this.assetsList[0].id;
        }
        setTimeout(() => {
          this.$refs.machine_model.focus();
        }, 10);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isDataLoading = false;
    }
  }

  isLoading = false;
  async onSubmitMaintenanceData() {
    try {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("asset_id", this.asset_id);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("scheduled_date", this.scheduled_date);
      formData.append("status", this.status);
      formData.append("type", this.type);
      formData.append("file", this.picture);
      formData.append("doc", this.document);

      const data = {
        // asset_id: this.asset_id,
        title: this.title,
        description: this.description,
        scheduled_date: this.scheduled_date,
        completion_date: this.completion_date,
        status: this.status,
        type: this.type,
        // spared_replaced: this.spared_replaced,
      };

      let response;
      if (this.isEditModeActive) {
        response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/${this.id}`,
          {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          }
        );
      } else {
        response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance`,
          {
            method: "POST",
            credentials: "include",
            body: formData,
          }
        );
      }

      if (response.status == 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Success!",
          text: "Maintenance added successfully",
          type: "Success",
        });
        this.$router.replace({ path: "/asset-tracker/maintenance" });
      } else if (response.status == 400) {
        const error = await response.json();
        switch (error[0].path[0]) {
          case "title":
            this.titleError = error[0].message;
            break;
          case "description":
            this.descriptionError = error[0].message;
            break;
          case "scheduled_date":
            this.scheduled_dateError = error[0].message;
            break;
          case "completion_date":
            this.completion_dateError = error[0].message;
            break;
          case "status":
            this.statusError = error[0].message;
            break;
          case "type":
            this.typeError = error[0].message;
            break;
          // case "cost_whole":
          //   this.cost_wholeError = error[0].message;
          //   break;
          // case "cost_decimal":
          //   this.cost_decimalError = error[0].message;
          //   break;
          case "asse_id":
            this.asset_idError = error[0].message;
            break;
          case "file":
            this.pictureError = error[0].message;
            break;
          case "doc":
            this.documentError = error[0].message;
            break;
          default:
            break;
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  picture: any = null;
  pictureError = "";
  photoList: Array<{
    fileName: string;
    pictureSrc: any;
  }> = [];
  appPictureUploaded(e: any) {
    this.pictureError = "";
    if (e.target.files.length > 0) {
      this.picture = e.target.files[0];
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        const reader = new FileReader();
        reader.onload = (result) => {
          const pictureSrc = result.target ? result.target.result : "";
          if (this.photoList.length > 0) {
            this.photoList.pop();
          }
          this.photoList.push({
            fileName: e.target.files[0].name,
            pictureSrc: pictureSrc,
          });
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        if (this.photoList.length > 0) {
          this.photoList.pop();
        }
      }
    } else {
      this.picture = null;
    }
  }

  onAddPhoto() {
    this.$refs.fileInput.click();
  }

  onRemovePhoto(index: number) {
    this.photoList = this.photoList.filter((e, i) => i !== index);
  }

  onAddDocument() {
    this.$refs.fileInput1.click();
  }

  document: any = null;
  documentError = "";
  documentList: Array<{
    fileName: string;
  }> = [];
  isUploading = false;
  appDocumentUpload(e: any) {
    this.documentError = "";
    if (e.target.files.length > 0) {
      if (
        e.target.files[0].type === "application/pdf" ||
        e.target.files[0].type === "application/doc"
      ) {
        this.document = e.target.files[0];
        if (this.documentList.length > 0) {
          this.documentList.pop();
        }
        this.documentList.push({
          fileName: e.target.files[0].name,
        });
      }
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: "No file selected",
        type: "Danger",
      });
    }
  }
}
